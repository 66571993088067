<template>
  <WeContainer v-if="ready" header="true" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:data="list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      list: [],
      actions: [
        {
          text: "Yeni Kullanıcı",
          path: "users/new",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        // {
        //   icon: "fas fa-trash",
        //   class: "btn-outline-danger ml-2",
        //   action: "remove",
        // },
      ],
      columns: [
        { name: "full_name", th: "Kullanıcı Adı Soyadı", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
    };
  },
  methods: {
    ...mapActions("user", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("users/detail/" + row.id);
    },
    onDelete(row) {
      let message =
        row.full_name + " adlı kullanıcıyı silmek istediğinize emin misiniz ?";

      this.$swal({
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$swal({
                    title: "Kullanıcı Başarıyla Silindi",
                    icon: "success",
                    confirmButtonText: "Tamam",
                  });
                } else {
                  this.$swal({
                    title: "Kullanıcı Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        const userId = this.session.userId;
        if (userId !== data.id) {
          this.isActive({
            id: data.id,
            is_active: data.is_active,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Durum Güncellendi");
              } else {
                this.$toast.error("Durum Güncellenemedi");
              }
            },
            onError: (error) => {
              this.$toast.error("Durum Güncellenemedi");
            },
          });
        } else {
          this.$toast.warning("Kullanıcı kendi durumunu güncelleyemez");
        }
      }
    },
    checkPermission(code) {
      return permission.check("user", code);
    },
  },
  computed: {
    ...mapState(["shared", "session"]),
  },
  mounted() {
    this.list = [];
    this.getList({
      onSuccess: (items) => {
        helper.copy(items, this.list);
        this.ready = true;
      },
    });
  },
};
</script>
